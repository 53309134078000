<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='title'>
        <a-input v-model='form.title' style="width: 350px" placeholder="内容标题" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='plus' @click='handleNew()'>新建</a-button>
        <a-divider type='vertical' />
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' size='default' :scroll='{ x: 1000}' :columns='columns' :data='loadData'>
        <span slot='cover' slot-scope='text'>
          <template>
            <img :src='text' width='180px'>
          </template>
        </span>
        <span slot='stitle' slot-scope='text'>
          <template>
            <ellipsis :length='20' tooltip>{{ text }}</ellipsis>
          </template>
        </span>
        <span slot='status' slot-scope='text'>
          <template>
            <a-badge v-if='text=="offline"' dot status='default' :text='"下架"' />
            <a-badge v-if='text=="online"' dot status='success' :text='"上架"' />
          </template>
        </span>
        <span slot='contentRichTextUrl' slot-scope='text'>
          <template>
            <a-space>
              <a-button type='primary' shape='circle' size='small' icon='eye' @click='handleView(text)' />
              <a-button type='primary' shape='circle' size='small' icon='copy' v-clipboard:copy='text' />
            </a-space>
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='handleEdit(record)'>编辑</a>
              <a v-if='record.status=="offline"' @click='handleStatus(record,"online")'>上架</a>
              <a v-if='record.status=="online"' @click='handleStatus(record,"offline")'>下架</a>
              <a @click='handleDelete(record)'>删除</a>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryCampusArticles, putCampusArticle, stickyCampusArticle, removeCampusArticle } from '@/api/content'

const columns = [
  {
    title: '内容标题',
    dataIndex: 'title',
    scopedSlots: { customRender: 'stitle' }
  },
  // {
  //   title: '内容封面',
  //   dataIndex: 'cover',
  //   scopedSlots: { customRender: 'cover' }
  // },
  {
    title: '所属栏目',
    dataIndex: 'subjectName',
    scopedSlots: { customRender: 'subjectName' }
  },
  {
    title: '内容链接',
    dataIndex: 'contentRichTextUrl',
    scopedSlots: { customRender: 'contentRichTextUrl' }
  },
  {
    title: '内容状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '排序优先级',
    dataIndex: 'priority',
    scopedSlots: { customRender: 'priority' }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'CaseList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      form: {
        title: ""
      },
      // 表头
      columns,
      queryParam: { prototype: 'case' },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryCampusArticles(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            return datum
          })
      }
    }
  },
  created() {

  },
  methods: {
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleNew() {
      this.$router.push('/content/case/edit')
    },
    handleEdit(record) {
      this.$router.push({ path: '/content/case/edit', query: { id: record.id } })
    },
    handleStatus(record, status) {
      putCampusArticle(Object.assign(record, { status: status })).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDelete(record) {
      removeCampusArticle({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleView(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
